<template>
  <div class="main-content">
    <div class="product-info-wrapper">
      <div class="content-block">
        <div class="container">
          <!-- 搜尋框 -->
          <ListSearchBlock
            class="full"
            placeholder="請輸入團友名稱"
            @on-search="updateSearch"
          ></ListSearchBlock>
          <!-- 團購資訊 -->
          <GroupBoard :group="followedList">
            <a
              v-if="isValidGroup"
              class="approve"
              data-toggle="modal"
              data-target="#modal-helper-approve"
            ></a>
            <a class="detail" @click="exportGroupDetails"></a>
          </GroupBoard>
        </div>
      </div>
      <div class="content-block">
        <div class="container">
          <table class="table table-striped table-success">
            <thead>
              <tr>
                <th scope="col">商品名稱</th>
                <th scope="col">人數</th>
                <th scope="col">數量</th>
                <th scope="col">小計</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in groupBuyingProductDetails"
                :key="product.id"
              >
                <th scope="row">{{ product.fullname }}</th>
                <td>{{ product.buyers_count }}</td>
                <td>
                  {{ product.qty }}
                </td>
                <td>
                  {{ product.subtotal }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 面交 -->
      <template v-if="followedList.delivery_type == 1">
        <OrderGroup
          v-for="(pickupLocation, index) in pickupLocations"
          @on-status-change="fetchFollowedList"
          :groupTypeId="`pickupLocation-${index}`"
          :orders="
            pickLocationOrders(pickupLocation.member_group_buying_orders)
          "
          :group-cover="followedList.cover"
          :key="index"
        >
          <template v-slot:group-title>
            {{ pickupLocation.time_from }}-{{ pickupLocation.time_to }} &nbsp;
            {{ pickupLocation.location }}
            <span>
              <template v-for="product in pickupLocation.products">
                {{ product.fullname }}: {{ product.quantity }}{{ product.unit }}
              </template>
            </span>
          </template>
        </OrderGroup>
      </template>
      <!-- 寄送 -->
      <template v-else>
        <OrderGroup
          @on-status-change="fetchFollowedList"
          groupTypeId="unpaid-group-orders"
          :orders="unpaidOrders"
          :group-cover="followedList.cover"
        >
          <template v-slot:group-title>未付款</template>
        </OrderGroup>
        <OrderGroup
          @on-status-change="fetchFollowedList"
          groupTypeId="paid-group-orders"
          :orders="paidOrders"
          :group-cover="followedList.cover"
        >
          <template v-slot:group-title>
            已付款
          </template>
        </OrderGroup>
        <OrderGroup
          @on-status-change="fetchFollowedList"
          groupTypeId="not-delivered-group-orders"
          :orders="notDeliveredOrders"
          :group-cover="followedList.cover"
        >
          <template v-slot:group-title>
            未寄送
          </template>
        </OrderGroup>
        <OrderGroup
          @on-status-change="fetchFollowedList"
          groupTypeId="delivered-group-orders"
          :orders="deliveredOrders"
          :group-cover="followedList.cover"
        >
          <template v-slot:group-title>
            已寄送
          </template>
        </OrderGroup>
      </template>
    </div>
    <MessageAlert
      :show="showMessageModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
    <div
      class="modal fade"
      id="modal-helper-approve"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">銀貨兩訖</div>
          <div class="helper-content">
            點擊確認後成員列表狀態都將更改成【確認已付款】、【確認已收貨】，活動也會移至歷史資料，請再次確認是否全部交易完成
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn cancel" data-dismiss="modal">取消</div>
            <div
              class="modal-btn"
              id="approve"
              data-dismiss="modal"
              @click.prevent="setCompleted"
            >
              確定
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListSearchBlock from '@/components/ListSearchBlock';
import GroupBoard from '@/components/GroupList/GroupBoard';
import OrderGroup from '@/components/CreatedGroup/OrderGroup';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

import {
  getGroupFollowList,
  getMyFollowedGroupList,
  getExportGroupListSignedUrl,
  forceComplete
} from '@/api/group-buying';
import { get } from 'lodash';

export default {
  components: {
    ListSearchBlock,
    GroupBoard,
    OrderGroup,
    MessageAlert
  },
  data() {
    return {
      followedList: {},
      search: null,
      //for message
      showMessageModal: false,
      msgTitle: null,
      msgContent: null,
      exportUrl: null
    };
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchFollowedList();
      }
    }
  },
  computed: {
    api() {
      if (this.$route.name === 'CreatedGroupFollowList') {
        return getGroupFollowList;
      }
      return getMyFollowedGroupList;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    isValidGroup() {
      return get(this.followedList, 'status') === 3 ? true : false;
    },
    paidOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_paid',
        []
      );

      return this.memberNameFilter(orders);
    },
    unpaidOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_unpaid',
        []
      );
      return this.memberNameFilter(orders);
    },
    deliveredOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_delivered',
        []
      );
      return this.memberNameFilter(orders);
    },
    notDeliveredOrders() {
      let orders = get(
        this.followedList,
        'member_group_buying_orders_notDelivered',
        []
      );
      return this.memberNameFilter(orders);
    },
    pickupLocations() {
      return get(this.followedList, 'pickup_locations', []);
    },
    groupBuyingProductDetails() {
      return get(this.followedList, 'group_buying_product_details', []);
    }
  },
  methods: {
    pickLocationOrders(orders) {
      return this.memberNameFilter(orders);
    },
    fetchFollowedList() {
      this.api(this.groupId)
        .then(data => {
          this.followedList = data;
        })
        .catch(() => {
          this.$router.push({
            name: 'CreatedGroupList'
          });
        });
    },
    exportGroupDetails() {
      getExportGroupListSignedUrl({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.exportUrl = data;
        this.$copyText(data).then(
          e => {
            this.showExportMsg();
          },
          e => {
            this.confirmMsg();
          }
        );
      });
    },
    updateSearch(search) {
      this.search = search;
    },
    memberNameFilter(orders) {
      if (!orders) {
        orders = [];
      }

      const searchName = this.search;

      if (!searchName) {
        return orders;
      }

      return orders.filter(order => {
        const name = get(order, 'member.name', '');

        return name.includes(searchName);
      });
    },
    confirmMsg() {
      if (this.exportUrl) {
        // window.open(this.exportUrl);
        window.location = this.exportUrl;
        this.exportUrl = null;
      }
      this.reset();
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    showExportMsg() {
      this.showMessageModal = true;
      this.msgTitle = '下載檔案連結已複製';
      this.msgContent =
        '檔案會自動下載，如無自動下載，請使用已複製的連結下載檔案';
    },
    setCompleted() {
      forceComplete(this.groupId).then(() => {
        this.fetchFollowedList();
      });
    }
  }
};
</script>
